import React from 'react';
import ToastProvider, { IToast, TToastType } from '../../providers/ToastProvider';
import styles from './Toast.module.scss';

interface IProps {
    children?: React.ReactNode;
}

const Toast = ({
    children,
}: IProps) => {
    const [toasts, setToasts] = React.useState<IToast[]>([]);
    const [index, setIndex] = React.useState(0);

    const toast = (type: TToastType, content: string) => {
        setIndex(index + 1);
        setToasts([
            ...toasts,
            { type, content, index }
        ])

        setTimeout(() => {
            clearToast(index);
        }, 2500)
    }

    const clearToast = (index: number) => {
        setToasts(toasts.filter(toast => toast.index !== index));
    }

    const classColorMap = {
        success: styles.Success,
        warning: styles.Warning,
        error: styles.Error,
        info: styles.Info,
    }

    return (
        <ToastProvider value={{ toasts, toast, clearToast }}>
            <div className={styles.ToastContainer}>
                {toasts.map(toast => (
                    <div
                        key={toast.index}
                        className={`
                            ${styles.Toast}
                            ${classColorMap[toast.type]}
                        `}
                        onClick={() => clearToast(toast.index)}
                    >
                        <div className={styles.Header}>
                            {toast.type[0].toUpperCase()}{toast.type.substring(1)}
                        </div>
                        <div className={styles.Content}>
                            {toast.content}
                        </div>
                    </div>
                ))}
            </div>
            {children}
        </ToastProvider>
    )
}

export default Toast;