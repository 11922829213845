import React from 'react';
import axios from 'axios';
import Panel from '../Panel/Panel';
import { useCallback } from 'react';
import { IBot } from '../../types/bot';
import BotStartStopButton from '../BotStartStopButton/BotStartStopButton';
import styles from './BotStatus.module.scss';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';

interface IProps {
    bot: IBot
    className?: string;
};

const BotStatus = ({
    bot,
    className,
}: IProps) => {
    const [isRunning, setIsRunning] = React.useState(false);
    const [output, setOutput] = React.useState<string[]>([]);
    const [isOutputLoading, setIsOutputLoading] = React.useState(false);

    const getStatus = useCallback(async () => {
        const response = await axios.get(`/admin/bots/${bot.id}/status`)

        setIsRunning(response.data.is_running)
    }, [bot])

    const getOutput = useCallback(async () => {
        setIsOutputLoading(true);
        const response = await axios.get<string[]>(`/admin/bots/${bot.id}/output?lines=25`)

        setOutput(response.data.map(line => line.trim()))
        setIsOutputLoading(false);
    }, [bot])

    React.useEffect(() => {
        getStatus();
        getOutput();
    }, [bot, getStatus, getOutput]);

    return (
        <Panel className={className}>
            <Panel.Header actions={[(
                <Button
                    size="sm"
                    onClick={getOutput}
                    key={'output'}
                >
                    Get Output
                </Button>
            ), (
                <BotStartStopButton
                    size="sm"
                    key={'status'}
                    bot={bot}
                    isRunning={isRunning}
                    onUpdate={getStatus}
                />
            )]}>
                Status: {isRunning ? 'Running' : 'Stopped'}
            </Panel.Header>
            <Panel.Body paddingless>
                <Loader active={isOutputLoading}>
                    Loading bot output...
                </Loader>
                <textarea className={styles.Logs} readOnly value={output.join('\n')} rows={10} />
            </Panel.Body>
        </Panel>
    );
}

export default BotStatus;