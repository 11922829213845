import React from 'react';
import Home from '../pages/Home/Home';
import Header from '../components/Header/Header';
import AdminSidebar from '../components/AdminSidebar/AdminSidebar';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import Bots from '../pages/Bots/Bots';
import styles from './App.module.scss';
import Loader from '../components/Loader/Loader';
import Toast from '../components/Toast/Toast';
import axios from 'axios';
import { AuthProvider, AuthService } from 'react-oauth2-pkce'

export interface TIDToken {
    sub: string;
    given_name: string;
    email: string;
    family_name: string;
    email_verified: boolean;
}

const authService = new AuthService<TIDToken>({
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    location: window.location,
    provider: process.env.REACT_APP_PROVIDER || '',
    redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
    scopes: ['openid', 'profile'],
    autoRefresh: true,
});

function App() {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    axios.interceptors.request.use(function (config) {
        if (authService.isAuthenticated()) {
            const idToken = authService.getAuthTokens().id_token;
            config.headers.Authorization = `Bearer ${idToken}`;
        }

        return config;
    });

    const routes = (
        <Switch>
            <Route path="/home">
                <Home />
            </Route>
            {authService.isAuthenticated() ? <>
                <Route exact path="/bots">
                    <Bots />
                </Route>
                <Route path="/bots/:id">
                    <Bots />
                </Route>
            </> : <Redirect to="/home" />}
            <Route path="/">
                <Redirect to="/home" />
            </Route>
        </Switch>
    );

    const app = (
        <AuthProvider authService={authService}>
            <div className={styles.App}>
                <Loader active={false} />
                <Header />
                <div className={styles.Body}>
                    <div className={`${styles.Sidebar} ${!isSidebarOpen ? styles.Collapsed : ''}`}>
                        <AdminSidebar
                            open={isSidebarOpen}
                            onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
                        />
                    </div>
                    <div className={styles.Content}>
                        {routes}
                    </div>
                </div>
            </div>
        </AuthProvider>
    );

    return (
        <Router>
            <Toast>
                {app}
            </Toast>
        </Router>
    );
}

export default App;
