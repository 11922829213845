import React from 'react';
import Table from '../Table/Table';
import { IBot } from '../../types/bot';
import Panel from '../Panel/Panel';

interface IProps {
    bot: IBot;
    className?: string;
}

const BotCommands = ({
    bot,
    className,
}: IProps) => {
    return (
        <Panel className={className}>
            <Panel.Header>Commands</Panel.Header>
            <Panel.Body>
                <Table>
                    <thead>
                        <tr>
                            <td><b>Command</b></td>
                            <td><b>Help</b></td>
                        </tr>
                    </thead>
                    <tbody>
                        {bot.commands.map(command => (
                            <tr key={command.name}>
                                <td>{command.name}</td>
                                <td>{command.help}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Panel.Body>
        </Panel>
    )
}

export default BotCommands;