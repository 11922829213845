import React from 'react';
import styles from './Table.module.scss';

interface IProps {
    children: React.ReactNode,
}

const Table = ({
    children
}: IProps) => {
    return (
        <table className={styles.Table}>
            {children}
        </table>
    )
}

export default Table;