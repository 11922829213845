import React from 'react';
import styles from './Form.module.scss';

interface IProps {
    children: React.ReactNode;
    className?: string;
    onSubmit?: () => void;
}

const Form = ({
    children,
    className,
    onSubmit,
}: IProps) => {
    const submitForm = (event: React.FormEvent) => {
        if (onSubmit) {
            onSubmit();
        };

        event.preventDefault();
    }

    return (
        <form className={`${styles.Form} ${className}`} onSubmit={submitForm}>
            {children}
        </form>
    )
}

export default Form;