import React from 'react';
import styles from './AdminSidebar.module.scss';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { BsGear } from 'react-icons/bs';

interface IListItemProps {
    title: string;
    icon?: any;
    iconRight?: any;
    onClick?: () => void;
}

interface IProps {
    open: boolean;
    onToggle: () => void;
}

const AdminSidebar = ({
    open, onToggle
}: IProps) => {
    const createListItem = (props: IListItemProps) => {
        if (open) {
            return (
                <div className={styles.ListItem} onClick={props.onClick}>
                    <div className={styles.ItemIconLeft}>
                        {props.icon}
                    </div>
                    <div className={styles.ItemLabel}>
                        {props.title}
                    </div>
                    {props.iconRight ? (
                        <div className={styles.ItemIconRight}>
                            {props.iconRight}
                        </div>
                    ) : null}
                </div>
            );
        }

        return (
            <div className={styles.ListItem} onClick={props.onClick}>
                <div className={styles.IconCollapsed}>
                    {props.icon ? props.icon : props.iconRight}
                </div>
            </div>
        )
    }

    return (
        <div className={styles.AdminSidebar}>
            <div className={styles.List}>
                {createListItem({
                    title: 'Test Button',
                    icon: <BsGear />
                })}
            </div>
            <div className={styles.Bottom}>
                {createListItem({
                    title: 'Collapse',
                    iconRight: open ? <HiChevronDoubleLeft /> : <HiChevronDoubleRight />,
                    onClick: onToggle
                })}
            </div>
        </div>
    );
};

export default AdminSidebar;