import React from 'react';
import { IBot } from '../../types/bot';
import axios from 'axios';
import Button from '../Button/Button';

interface IProps {
    bot: IBot;
    isRunning: boolean;
    onUpdate?: () => void;
    [name: string]: any;
}

const BotStartStopButton = ({
    bot,
    isRunning,
    onUpdate,
    ...props
}: IProps) => {
    const startStop = async () => {
        await axios.post(`/admin/bots/${bot.id}/${isRunning ? 'stop' : 'start'}`)

        if (onUpdate) {
            onUpdate();
        }
    }

    return (
        <Button
            {...props}
            onClick={startStop}
            variant={isRunning ? 'dark' : 'light'}
        >
            {isRunning ? 'Stop' : 'Start'}
        </Button>
    )
}

export default BotStartStopButton;