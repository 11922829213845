import React from 'react';
import styles from './Panel.module.scss';

interface IPanelProps {
    children: React.ReactNode,
    className?: String;
    centered?: Boolean;
}

//
// Panel
//

const Panel = ({
    children,
    className,
    centered,
}: IPanelProps) => {
    const subComponents = Object.keys(Panel).map(key => {
        return React.Children.map(children, (child: any) => {
            return child.type.name === key ? child : null;
        });
    });

    return (
        <div className={`${styles.Panel} ${centered ? styles.Centered : ''} ${className ? className : ''}`}>
            {subComponents}
        </div>
    );
}

interface IHeaderProps {
    children: React.ReactNode,
    actions?: React.ReactNode | React.ReactNode[],
}

//
// Header
//

const Header = ({
    children,
    actions,
}: IHeaderProps) => {
    return (
        <div className={styles.Header}>
            <div className={styles.Title}>
                {children}
            </div>
            {actions && (
                <div className={styles.Actions}>
                    {actions}
                </div>
            )}
        </div>
    )
}

interface IBodyProps {
    children: React.ReactNode,
    paddingless?: boolean,
}

//
// Body
//

const Body = ({
    children,
    paddingless,
}: IBodyProps) => {
    return (
        <div className={`${styles.Body} ${paddingless ? styles.Paddingless : ''}`}>
            {children}
        </div>
    )
}

interface IFooterProps {
    children: React.ReactNode,
}

//
// Footer
//

const Footer = ({
    children
}: IFooterProps) => {
    return (
        <div className={styles.Footer}>
            {children}
        </div>
    )
}

Panel.Header = Header;
Panel.Body = Body;
Panel.Footer = Footer;

export default Panel;