import React from 'react';
import axios from 'axios';
import BotEdit from '../../../components/BotEdit/BotEdit';
import BotCommands from '../../../components/BotCommands/BotCommands';
import { IBot } from '../../../types/bot';
import BotStatus from '../../../components/BotStatus/BotStatus';
import styles from './Bot.module.scss';
import Loader from '../../../components/Loader/Loader';

interface IProps {
    id: string;
}

const Bot = ({
    id
}: IProps) => {
    const [bot, setBot] = React.useState<IBot>({
        id: id,
        commands: [],
        module: '',
        name: '',
        token: ''
    });

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        const getBot = async () => {
            setIsLoading(true);
            const response = await axios.get(`/admin/bots/${id}`)
            setBot(response.data);
            setIsLoading(false);
        }

        getBot();
    }, [id]);

    return (
        <div className={styles.Bot}>
            <Loader active={!bot || isLoading}>
                Loading bot {id}...
            </Loader>
            <div className={styles.TopRow}>
                <BotEdit bot={bot} className={styles.BotEdit} />
                <BotCommands bot={bot} className={styles.BotCommands} />
            </div>
            <div className={styles.BottomRow}>
                <BotStatus bot={bot} className={styles.BotStatus} />
            </div>
        </div>
    )
}

export default Bot;