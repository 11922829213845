import { useParams } from 'react-router-dom';
import BotTable from '../../components/BotTable/BotTable';
import Bot from './Bot/Bot';

interface IParams {
    id?: string;
}

const Bots = () => {
    const params: IParams = useParams();

    if (params.id === undefined) {
        return <BotTable />
    }

    return <Bot id={params.id} />
}

export default Bots;