import Table from '../Table/Table';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { IBot } from '../../types/bot';

const BotTable = () => {
    const [bots, setBots] = React.useState<IBot[]>([]);

    React.useEffect(() => {
        const getBots = async () => {
            const response = await axios.get('/v1/user/bots');

            setBots(response.data);
        }

        getBots();
    }, [])


    return (
        <Table>
            <thead>
                <tr>
                    <td>Bot ID</td>
                    <td>Name</td>
                </tr>
            </thead>
            <tbody>
                {bots.map(bot => (
                    <tr key={bot.bot_id}>
                        <td><Link to={`/bots/${bot.bot_id}`}>{bot.bot_id}</Link></td>
                        <td>{bot.name}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default BotTable;