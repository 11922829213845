import React from 'react';
import styles from './Button.module.scss';

interface IProps {
    onClick?: () => void;
    children?: React.ReactNode,
    variant?: 'light' | 'dark';
    className?: string;
    size?: 'sm' | 'lg';
}

const Button = ({
    onClick,
    children,
    variant,
    className,
    size,
}: IProps) => {
    if (!onClick) {
        onClick = () => { };
    }

    return (
        <button
            className={`
                ${styles.Button}
                ${size === 'sm' ? styles.Small : styles.Large}
                ${variant === 'dark' ? styles.Dark : styles.Light}
                ${className}
            `}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default Button;