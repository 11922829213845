import React from 'react';
import styles from './Loader.module.scss';

interface IProps {
    active: boolean;
    children?: React.ReactNode,
}

const Loader = ({
    active,
    children,
}: IProps) => {
    if (!active) {
        return null;
    }

    return (
        <div className={styles.Overlay}>
            <div className={styles.SpinnerContainer}>
                <div className={styles.Spinner} />
            </div>
            <div className={styles.LoadingText}>
                {children}
            </div>
        </div>
    );
}

export default Loader;