import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { BsChevronDown } from 'react-icons/bs';
import { useAuth } from 'react-oauth2-pkce';
import { TIDToken } from '../../App/App';

const Header = () => {
    const [openDropdown, setOpenDropdown] = React.useState<string | null>();
    const { authService } = useAuth();

    const activateDropdown = (key: string) => setOpenDropdown(key === openDropdown ? null : key);

    interface INav {
        display: string;
        to?: string;
        href?: string;
        onClick?: () => void;
        dropdown?: React.ReactNode;
    }

    const leftNavs: INav[] = [{
        display: 'Home',
        to: '/home'
    }, {
        display: 'Bots',
        to: '/bots'
    }];

    const logout = () => {
        authService.logout();

        const { clientId, provider, redirectUri } = authService.props;
        const query = new URLSearchParams({
            client_id: clientId,
            logout_uri: redirectUri || '',
        })

        const url = `${provider}/logout?${query}`
        window.location.replace(url);
    }

    const rightNavs: INav[] = [authService.isAuthenticated() ? {
        display: `Hello, ${(authService.getUser() as TIDToken).given_name}`,
        dropdown: (
            <a href="#" onClick={logout}>Logout</a>
        ),
    } : {
        display: 'Login or Signup',
        onClick: () => { authService.authorize() }
    }];

    const createNav = (navs: INav[]) => {
        return navs.map(nav => (
            <div className={styles.NavItem} key={nav.display}>
                {nav.to && (
                    <Link to={nav.to}>
                        {nav.display}
                    </Link>
                )}
                {nav.href && (
                    <a href={nav.href}>
                        {nav.display}
                    </a>
                )}
                {nav.onClick && (
                    <a href="#" onClick={nav.onClick}>
                        {nav.display}
                    </a>
                )}
                {nav.dropdown && (
                    <>
                        <a
                            className={openDropdown === nav.display ? styles.Active : ''}
                            href="#" onClick={() => activateDropdown(nav.display)}
                        >
                            {nav.display}
                            <BsChevronDown />
                        </a>
                        {openDropdown === nav.display && (
                            <div className={styles.Dropdown}>
                                {nav.dropdown}
                            </div>
                        )}
                    </>
                )}
            </div>
        ))
    }

    return (
        <div className={styles.Header}>
            <Link className={styles.Brand} to="/home">Chat Bots</Link>
            <div className={styles.Nav}>
                <div className={styles.NavLeft}>
                    {createNav(leftNavs)}
                </div>
                <div className={styles.NavRight}>
                    {createNav(rightNavs)}
                </div>
            </div>
        </div>
    )
}

export default Header;