import React from 'react';

export type TToastType = 'success' | 'warning' | 'error' | 'info';

export interface IToast {
    type: TToastType;
    content: string;
    index: number;
}

export interface IToastContext {
    toasts: IToast[];
    toast: (type: TToastType, content: string) => void;
    clearToast: (idx: number) => void;
}

export const ToastContext = React.createContext<IToastContext>({
    toasts: [],
    toast: (type: TToastType, content: string) => { },
    clearToast: (idx: number) => { },
});

const ToastProvider = ToastContext.Provider;

export default ToastProvider;