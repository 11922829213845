import axios from 'axios';
import React from 'react';
import { IBot } from '../../types/bot';
import Form from '../Form/Form';
import Panel from '../Panel/Panel';
import Button from '../Button/Button';
import { ToastContext } from '../../providers/ToastProvider';

interface IProps {
    bot: IBot;
    className?: string;
}

interface IForm {
    token: string;
}

const BotEdit = ({
    bot,
    className,
}: IProps) => {
    const [form, setForm] = React.useState<IForm>({
        token: '',
    });
    const { toast } = React.useContext(ToastContext);

    const handleChange = (name: keyof IForm, value: string) => {
        setForm({
            ...form,
            [name]: value
        })
    };

    const save = async () => {
        await axios.patch(`/admin/bots/${bot.id}/token`, {
            'token': bot.token
        });

        toast('success', 'Successfully updated bot');
    }

    return (
        <Panel className={className}>
            <Panel.Header actions={[
                <Button
                    size="sm"
                    onClick={() => save()}
                    key={'save'}
                >
                    Save
                </Button>
            ]}>
                Edit {bot.name}
            </Panel.Header>
            <Panel.Body>
                <Form>
                    <label>Token</label>
                    <input
                        type="input"
                        value={bot.token}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => handleChange('token', ev.target.value)}
                    />
                </Form>
            </Panel.Body>
            <Panel.Footer>
                ID: {bot.id}
                <br />
                Module: {bot.module}
            </Panel.Footer>
        </Panel>
    )
}

export default BotEdit;